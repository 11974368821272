import { Layout } from 'antd';
import dayjs from 'dayjs';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

export default function Footer (): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);

  function renderYear (): string {
    const startYear = 2021;
    const endYear = dayjs().year();

    if (startYear !== endYear) {
      return `${startYear}–${endYear}`;
    }

    return `${endYear}`;
  }

  return (
    <Layout.Footer className="center">
      <small>&copy; Copyright {renderYear()}, {data.site.siteMetadata.author}</small>
    </Layout.Footer>
  );
}
