import { DownloadOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

interface CiteThisProps {
  date    : string;
  title   : string;
}

export default function CiteThis (props: CiteThisProps): JSX.Element {
  const isBrowser = (typeof window !== 'undefined');

  const { date, title } = props;

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          authorPlain
          siteUrl
          title
        }
      }
    }
  `);

  const { authorPlain, siteUrl, title: siteTitle } = data.site.siteMetadata;

  function generateCiteKey () {
    if (!isBrowser) return '';

    return (
      `roberts-yesanechi${
        window.location.pathname
          .replaceAll('/', '-')
          .replace(/-$/, '')
      }`
    );
  }

  function getUrl () {
    if (!isBrowser) return '';

    return `${siteUrl}${window.location.pathname}`;
  }

  const citekey = generateCiteKey();
  const year    = dayjs(date).format('YYYY');
  const url     = getUrl();

  const biblatex = [
    `@online{${citekey},`,
    `  author  = {${authorPlain}},`,
    `  title   = {${title}},`,
    `  year    = {${year}},`,
    `  url     = {${url}},`,
    `  urldate = {${dayjs().format('YYYY-MM-DD')}}`,
    '}',
  ].join('\n');

  const bibtex = [
    `@misc{${citekey},`,
    `  author       = {${authorPlain}},`,
    `  title        = {${title}},`,
    `  year         = {${year}},`,
    `  howpublished = {\\url{${url}}},`,
    `  url          = {${url}},`,
    `  note         = {[Online; accessed ${dayjs().format('DD-MMMM-YYYY')}]}`,
    '}',
  ].join('\n');

  return (
    <>
      <div className="spacer-bottom" />
      <Collapse className="full-width">
        <Collapse.Panel
          header="Cite This Page"
          key="cite-this"
        >
          <div className="cite-this-citation-header">Journal of the Linguistic Society of America</div>
          <div>
            <span className="citation-author">{authorPlain}</span>
            {'. '}
            {year}
            {'. '}
            {title}
            {'. '}
            <span className="citation-title-website">{siteTitle}</span>
            {'. '}
            Online: {url}
            {'.'}
          </div>

          <hr />

          <div className="cite-this-citation-header">APA</div>
          <div>
            <span>{authorPlain}</span>
            {'. '}
            ({dayjs(date).format('YYYY, MMMM DD')})
            {'. '}
            <span className="italic">{title}</span>
            {'. '}
            {siteTitle}
            {'. '}
            Retrieved {dayjs().format('MMMM DD, YYYY')}, from {url}
          </div>

          <hr />

          <div className="cite-this-citation-header">MLA</div>
          <div>
            {authorPlain}
            {'. '}
            &ldquo;{title}.&rdquo;
            {' '}
            <span className="italic">{siteTitle}</span>
            {', '}
            {url.replace(/https?:\/+/, '')}
            {'. '}
            Accessed {dayjs().format('DD MMMM YYYY')}
            {'.'}
          </div>

          <hr />

          <div className="cite-this-citation-header">Chicago</div>
          <div>
            {authorPlain}
            {', '}
            &ldquo;{title},&rdquo;
            {' '}
            <span className="italic">{siteTitle}</span>
            {', '}
            {url} (accessed {dayjs().format('MMMM DD, YYYY')})
            {'.'}
          </div>

          <hr />

          <div
            className="cite-this-citation-header cite-this-citation-header-has-download"
            onClick={() => download(biblatex, `${citekey}.bib`, 'text/plain')}
          >
            BibLaTeX
            <DownloadOutlined className="cite-this-download-icon" />
          </div>
          <pre className="cite-this-pre">{biblatex}</pre>

          <hr />

          <div
            className="cite-this-citation-header cite-this-citation-header-has-download"
            onClick={() => download(bibtex, `${citekey}.bib`, 'text/plain')}
          >
            BibTeX
            <DownloadOutlined className="cite-this-download-icon" />
          </div>
          <pre className="cite-this-pre">{bibtex}</pre>
        </Collapse.Panel>
      </Collapse>
    </>
  );
}
