import React, { ReactNode } from 'react';

import Layout from '../Layout';

import './Global.scss';

interface LayoutComponentProps {
  children: ReactNode;
}

export default function Global (props: LayoutComponentProps): JSX.Element {
  const { children } = props;

  return (
    <Layout>
      {children}
    </Layout>
  );
}
