import { Layout } from 'antd';
import React, { ReactNode } from 'react';

interface MainProps {
  children: ReactNode;
}

export default function Main (props: MainProps): JSX.Element {
  const { children } = props;

  return (
    <Layout.Content className="content-main">
      {children}
    </Layout.Content>
  );
}
