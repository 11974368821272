export function smartypants (input = ''): string {
  return input
    .replace(/'\b/g, '\u2018')   // left smart double quote
    .replace(/\b'/g, '\u2019')   // right smart double quote
    .replace(/"\b/g, '\u201c')   // left smart double quote
    .replace(/\b"/g, '\u201d')   // right smart double quote
    .replace(/--/g,  '\u2014')   // em dashes
    .replace(/---/g, '\u2013')   // en dashes
    .replace(/\b\u2018\b/g, "'") // normal apostrophes
  ;
}
