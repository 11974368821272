import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  title       : string;
  description : string;
}

export default function SEO (props: SEOProps): JSX.Element {
  const { title, description } = props;

  const location = useLocation();
  const data     = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
        }
      }
    }
  `);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
  } = data.site.siteMetadata;

  const keywords = [
    'Tutelo',
    'Saponi',
    'Tutelo-Saponi',
    'Sapony',
    'Yesanechi',
    'Yesanēchi',
    'Corey Roberts',
    'Corey Roberts, MA',
    'Corey Roberts, M.A.',
    'Corey Roberts, PhD',
    'Corey Roberts, Ph.D.',
    'Dr. Corey Roberts, MA',
    'Dr. Corey Roberts, M.A.',
    'Dr. Corey Roberts, PhD',
    'Dr. Corey Roberts, Ph.D.',
    'Corey J. Roberts',
    'Corey J. Roberts, MA',
    'Corey J. Roberts, M.A.',
    'Corey J. Roberts, PhD',
    'Corey J. Roberts, Ph.D.',
    'Dr. Corey J. Roberts, MA',
    'Dr. Corey J. Roberts, M.A.',
    'Dr. Corey J. Roberts, PhD',
    'Dr. Corey J. Roberts, Ph.D.',
    'Siouan language',
    'Siouan languages',
    'Native American language',
    'Native American languages',
    'Occaneechi',
    'Haliwa-Saponi',
    'Monacan,',
    'Saponi Nation of Ohio',
  ];

  const seo = {
    description : description || defaultDescription,
    keywords    : keywords.join(', '),
    title       : title || defaultTitle,
    url         : `${siteUrl}${location.pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang : 'en',
      }}
    >
      <meta name="theme-color"        content="#001529"         />

      <meta name="description"        content={seo.description} />
      <meta name="keywords"           content={seo.keywords}    />

      <meta property="og:description" content={seo.description} />
      <meta property="og:title"       content={seo.title}       />
      <meta property="og:url"         content={seo.url}         />
    </Helmet>
  );
}
