import {
  AppstoreOutlined,
  BookOutlined,
  BranchesOutlined,
  DollarOutlined,
  FileAddOutlined,
  FileUnknownOutlined,
  FontSizeOutlined,
  HomeOutlined,
  MessageOutlined,
  PicRightOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SoundOutlined,
  TableOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React, { ReactNode } from 'react';

export interface MenuItem {
  name : string;
  link : string;
  icon : ReactNode;
}

export interface MenuGroup {
  groupName : string;
  items     : MenuItem[];
}

type MenuItems = (MenuItem|MenuGroup)[];

const allMenuItems: MenuItems = [
  { name: 'Home',                      link: '/',                         icon: <HomeOutlined />           },
  { name: 'Articles',                  link: '/articles/',                icon: <PicRightOutlined />       },
  {
    groupName : 'About',
    items     : [
    { name: 'The Yesanēchi Language',  link: '/about/yesanechi/',         icon: <MessageOutlined />        },
    { name: 'The Tribes',              link: '/about/tribes/',            icon: <TeamOutlined />           },
    { name: 'Corey J. Roberts, M.A.',  link: '/about/corey/',             icon: <UserOutlined />           },
  ]},
  {
    groupName : 'Resources',
    items     : [
    { name: 'Phonemes',                link: '/resources/phonemes/',      icon: <AppstoreOutlined />       },
    { name: 'Orthography',             link: '/resources/orthography/',   icon: <FontSizeOutlined />       },
    { name: 'Sound Rules (Phonology)', link: '/resources/phonology/',     icon: <SoundOutlined />          },
    { name: 'Lessons',                 link: '/resources/lessons/',       icon: <ReadOutlined />           },
    // { name: 'Dictionary',              link: '/resources/dictionary/',    icon: <BookOutlined />           },
    // { name: 'Conjugation',             link: '/resources/conjugation/',   icon: <TableOutlined />          },
  ]},
  {
    groupName : 'Contribute',
    items     : [
    { name: 'Donate',                  link: '/contribute/donate/',       icon: <DollarOutlined />         },
    // { name: 'Request a Word',          link: '/contribute/request-word/', icon: <FileUnknownOutlined />    },
    // { name: 'Suggest a Word',          link: '/contribute/suggest-word/', icon: <FileAddOutlined />        },
    // { name: 'Ask a Question',          link: '/contribute/ask-question/', icon: <QuestionCircleOutlined /> },
  ]},
  { name: 'Bibliography',              link: '/bibliography/',            icon: <UnorderedListOutlined />  },
  { name: 'Other Resources',           link: '/other-resources/',         icon: <BranchesOutlined />       },
];

// user defined type guard
export function isMenuGroup (input: MenuItem | MenuGroup): input is MenuGroup {
  return input.hasOwnProperty('groupName');
}

function getMenuItems () {
  const menuItems: MenuItems = [];

  allMenuItems.forEach((menuItem) => {
    if (isMenuGroup(menuItem)) {
      const menuGroup: MenuGroup = {
        groupName : menuItem.groupName,
        items     : [],
      };

      menuItem.items.forEach((groupItem) => {
        menuGroup.items.push(groupItem);
      });

      menuItems.push(menuGroup);
    } else {
      menuItems.push(menuItem);
    }
  });

  return menuItems;
}

export default getMenuItems();
