import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';
import Sidebar from '../Sidebar';

interface LayoutComponentProps {
  children: ReactNode;
}

export default function LayoutComponent (props: LayoutComponentProps): JSX.Element { // to avoid import collision
  const { children } = props;

  return (
    <Layout>
      <Sidebar />
      <Layout className="content-container">
        <Header className="portrait-only" />
        <Main>
          {children}
        </Main>
        <Footer />
        </Layout>
    </Layout>
  );
}
