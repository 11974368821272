import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Layout, Menu } from 'antd';
import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Location } from '@reach/router';

import Header from '../Header';

import menuItems, { isMenuGroup, MenuGroup, MenuItem } from '../../menu-items';

export default function Sidebar (): JSX.Element {
  const [ isPortraitSidebarOpen, setIsPortraitSidebarOpen ] = useState(false);

  function renderMenuItem (item: MenuItem) {
    return (
      <Menu.Item
        key={item.link}
        icon={item.icon}
      >
        <Link to={item.link}>{item.name}</Link>
      </Menu.Item>
    );
  }

  function renderMenuGroup (group: MenuGroup) {
    return (
      <Menu.ItemGroup
        key={group.groupName}
        title={group.groupName}
      >
        {group.items.map((item) => renderMenuItem(item))}
      </Menu.ItemGroup>
    );
  }

  function renderMenuItems () {
    return menuItems.map((item) => {
      if (isMenuGroup(item)) {
        return renderMenuGroup(item);
      } else {
        return renderMenuItem(item);
      }
    });
  }

  function renderMenu (selectedKey: string) {
    return (
      <Menu
        mode="vertical"
        theme="dark"
        selectedKeys={[
          // handle all the ways this might show up
          // to support single-paged-app navigation
          // (usually without trailing slash) AND
          // hard refresh (which includes trailing
          // slash)
          selectedKey,
          `${selectedKey}/`,
          selectedKey.replace(/\/$/, ''),
          selectedKey.includes('/articles/') ? '/articles' : '', // special case
        ]}
      >
        {renderMenuItems()}
      </Menu>
    );
  }

  return (
    <Location>
      { // to select the correct menu item
        (props) => {
          function renderDesktopSidebar () {
            return (
              <Layout.Sider className={classnames(
                'sidebar-sider',
                'landscape-only',
              )}>
                <Header />
                {renderMenu(props.location.pathname)}
              </Layout.Sider>
            );
          }

          function renderPortraitSidebar () {
            return (
              <div className={classnames(
                'portrait-only',
              )}>
                <Button
                  className="sidebar-drawer-toggle"
                  type="primary"
                  icon={<MenuOutlined />}
                  onClick={() => setIsPortraitSidebarOpen(!isPortraitSidebarOpen)}
                />

                <Drawer
                   className={classnames(
                    'portrait-only', // repeated here because this is appended to the DOM
                  )}
                  closable={false}
                  visible={isPortraitSidebarOpen}
                  onClose={() => setIsPortraitSidebarOpen(false)}
                >
                  {renderMenu(props.location.pathname)}
                </Drawer>
              </div>
            );
          }

          return (
            <>
              {renderDesktopSidebar()}
              {renderPortraitSidebar()}
            </>
          );
        }
      }
    </Location>
  );
}
